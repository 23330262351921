import React, { useState, useContext, useRef, useReducer, useEffect} from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import AthletesTable from 'components/cronoapp/AthletesTable';
import { AppContext } from '../store/createContext';
import {
  Grommet,
  Box,
  Tab,
  Tabs,
} from 'grommet';
import TableToolBar from 'components/cronoapp/TableToolBar';
import jstz from 'jstz';
import { sortByPosition, filterByAttributes, processAthletes } from '../utils/AthletesHandlers';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useAsyncTask, useAsyncRun } from 'react-hooks-async';

const fetchRaceTable = async ({ signal }, raceid) => {
  const response = await fetch(
    `${process.env.GATSBY_DRUPAL_API_URL}/api/match/${raceid}/full`,
    { signal }
  );
  const data = await response.json();
  return data.data;
};

const TableHero = (props) => {
  console.log(props.id)

  const [filterInput, setFilterInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      gender: '',
      category: '',
      start_date: '',
      start_mode: 'custom',
    }
  );

  const handleFilterAthletes = ({ value, filter }) => {
    //console.log('Set Filter',   value, filter)
    setFilterInput({ [filter]: value });
  };

  const task = useAsyncTask(fetchRaceTable);
  useAsyncRun(task, props.id)
  const {start, pending, error, result, abort } = task;
  if (pending) return <div>Loading...<button onClick={abort}>Abort</button></div>;
  if (error) return <div>Error: {error.name} {error.message}</div>;
  if (_.isUndefined(result.athletes)) return <div>No se encontro una carrera con ese id()!!</div>;
  const filters = result
    ? {
        categories: _.uniq(
          result.athletes.map(obj => {
            return obj['category'];
          })
        ).concat(''),
        genders: _.uniq(
          result.athletes.map(obj => {
            return obj['gender'];
          })
        ).concat(''),
      }
    : {};

  const timezone = jstz.determine();
  //const componentIsMounted = useRef(true);
  const columns = result ? result.race_columns : [];
  const startDate = result ? parseInt(result.start_date) : null;

  const handleRefreshClick = (raceid) => {
    //console.log("refresh",raceid)
    start(raceid);
  };

  const filterAthletes = list => {
    //console.log('Filters', filterInput)
    return list.filter(item => {
      return (
        item.category
          .toLowerCase()
          .includes(filterInput.category.toLowerCase()) &&
        item.gender.toLowerCase().includes(filterInput.gender.toLowerCase())
      );
    });
  };

  //Create Athletes array with the filters applied and Ordered by Time(best time on last checkpoint record added.)
  const athletes =
    result && sortByPosition(filterAthletes(processAthletes(result.athletes,columns,startDate, filterInput)), columns, filterInput);
    ///result && console.log("LISTA LIMPIA", result.athletes)
  //const id = load ? raceid : raceid.raceid
console.log(result)
  return (

    <Layout>

    <Box margin="small" pad="xxsmall" align="stretch" border="false" animation="fadeIn" flex="grow" >


          <TableToolBar
            handleRefreshClick={() => handleRefreshClick(props.id)}
            handleFilterAthletes={handleFilterAthletes}
            filterInput={filterInput}
            filters={filters}
            active={true}
            race_name={result.event.name +" :: "+result.title}
           />
          <AthletesTable
            key={result.id}
            athletes={Object(athletes)}
            columns={Object(columns)}
            start_date={startDate}
            start_mode={filterInput.start_mode}
          />

    </Box>

    </Layout>

  );
};


export default TableHero;
